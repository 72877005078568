@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&display=swap');

li {
    @apply font-mono text-center text-gray-300 opacity-90 font-bold
}

/** animations **/

@keyframes transitionAnimateHeader {
    0% {
        opacity: 0;
        clip-path: polygon(12% 0, 100% 0%, 88% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(12% 0, 100% 0%, 88% 100%, 0% 100%);
    }
}

@keyframes transitionFadeIn {
    0% {
        opacity: 0;
    }
}

/** custom tailwind CSS **/

.border-header {
    @apply h-1 w-full bg-gradient-to-r from-white to-slate-900 to-85% opacity-20
}

.border-to-black {
    @apply h-2 w-10/12 bg-gradient-to-r from-white to-zinc-900 to-90% opacity-60
}

.border-to-white {
    @apply border-to-black rotate-180
}

.content-div:not(:last-of-type) {
    margin-bottom: 40px;
}

.min-w-50 {
    min-width: 50px;
}

.min-w-100 {
    min-width: 100px;
}

.separator {
    @apply bg-gray-300 bg-opacity-80 w-4 sm:w-1 shadow-sm shadow-gray-400
}

.text-secondary {
    @apply text-gray-300 font-poppins font-normal p-1
}

.text-nav {
    @apply text-gray-300 font-mono
}

.text-primary {
    @apply text-gray-300 font-quicksand font-semibold
}

.text-shadow-white {
    text-shadow: 0.5px 0.5px 6px white;
}

/** Other CSS **/

#aboutMeDiv, #descriptionDiv, #experienceDiv, #navbarDiv, #educationDiv, #projectDiv {
    animation: transitionFadeIn ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#cv-button {
    max-width: 115px;
}

.fadeInElement {
    animation: transitionFadeIn ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#headerDiv {
    animation: transitionAnimateHeader ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#og-header-div {
    @media (min-width: 639px)
    {
        min-width: 900px;
    }
}

#navSelect:not(:last-of-type) {
    margin-bottom: 10px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;